
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px !important;
        color:rgb(128,128, 128);
        font-size:12px;
    }
    td {
        padding: 2px !important ;
        font-size:11px;
    }   
}
.mt-9{
    margin-top: 9px;
}
.item-width  {
    // width: 24%;
    // color:#409EFF;
}
.el-dialog__body{
    padding: 1px 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn { margin-top:-10px;  }
.el-dialog__headerbtn i { background:white;font-size: 20px; }
