



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px !important;
        color:rgb(128,128, 128);
        font-size:12px;
    }
    td {
        padding: 5px ;
        font-size:11px;
    }   
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color :#3d77eb
}
.el-checkbox__input.is-disabled+span.el-checkbox__label{
    color:#3d77eb
}
.el-dialog__body{
    padding: 1px 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn { margin-top:0px;  }
.el-dialog__headerbtn i { background:white;font-size: 18px; }
.messageBox .el-message-box__content{
    border-top: 1px solid #EBEEF5;
    height: 50px;
}
.el-message-box__message p{
    line-height: 25px;
}
.messageBox .el-message-box__btns{
    padding: 20px 15px 0;
}
.messageBox .el-message-box__header{
    background:#409eff;
}
.messageBox .el-message-box__title{
    color:white ;
    font-size:14px;
}
