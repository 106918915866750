.main_back {
  margin: 10px auto;
  width: 99%;
  border: 1px solid #fff;
}
.main_back .hor {
  text-align: center;
  background: #F0F8FF;
}
.formitem {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 620px;
  height: 20px;
}
.fontsize {
  font-size: 14px;
  font-weight: 600;
}
.elbutton {
  margin-top: 5px;
  width: 400px;
  margin-left: 90px;
  height: 40px;
  margin-bottom: 10px;
}
.selsectbox {
  height: 10px !important;
  width: 90px;
  border-radius: 1px;
  color: red;
  font-size: 14px !important;
  font-weight: 550;
}
.container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
