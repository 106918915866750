






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































    .deptdiv{
       border:1px solid #e9e9e9;
       height:670px;
       overflow:auto;
       width:350px;       
   }
   .flddiv{
       border:1px solid #e9e9e9;
       height:400px;
       overflow:auto;
       width:350px;       
   }
   .btn{
       width:80%;
       margin-top:100px;
       height:40px;
       margin-left:5px;
       margin-right:5px;
   }
   .dashbtn{
       height:35px;
       width:90px;
 
   }
.selinput{
    width:300px;
    margin-top:2px;
    margin-left:10px;
}
.colwidth{
    width:140px;
}
.colwidth2{
    width:642px;
}
.oneMenu{
        line-height:35px;
        text-align:left;
        padding-left:20px;
        font-size:14px;
        font-weight:550;
        color:#5cadff;
        background-color: #dcdee2;  
        display:flex;
    }
    .oneMenu:hover{
        cursor: pointer;
        color:#19be6b;
    }
    .twoMenu{
        display:flex;
        flex-direction:column;
        line-height:40px;
        background:white;
        padding-left:35px;
    }
    .twoMenu:hover{
        cursor: pointer;
        color:#19be6b;
        background:#e8eaec;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
